const colors = {
    YOUTUBE: "#ff0000",
    TWITTER: "#1da1f2",
    INSTAGRAM: "#7232bd",
    PATREON: "#f96854"
};

const links = {
    YOUTUBE: "https://www.youtube.com/channel/UCZXbWcv7fSZFTAZV4beckyw",
    TWITTER: "https://twitter.com/sham00k",
    INSTAGRAM: "https://instagram.com/sham.00k/",
    PATREON: "https://patreon.com/sham00k"
};

const images = {
    BANNER_NAME: process.env.PUBLIC_URL+'/img/Sham00k_Name.png',
};

const webAddresses = {
    SHAMOOK_MELLIGANS: "https://www.sham00k.com",
};

export { colors, links, images, webAddresses };