import React, { Component } from 'react';
import {
    Jumbotron,
    Container
} from 'react-bootstrap';

import {
    Youtube,
    Twitter,
    Instagram,
    Patreon
} from '../utils/ContactWidgets';
import {
    images,
    links
} from '../utils/Constants';

const size = 50;

class HeaderSplash extends Component {

    render() {
        return(
            <span className="HeaderSpash">
                <Jumbotron className="HeaderSplash-Jumbotron">
                    <Container className="HeaderSplash-Container">
                        <img className="HeaderSplash-Name" src={images.BANNER_NAME} alt="ShamookName"/>
                        <span className="HeaderContacts">
                            <Youtube size={size} href={links.YOUTUBE}>
                                YouTube
                            </Youtube>
                            <Twitter size={size} href={links.TWITTER}>
                                Twitter
                            </Twitter>
                            <Instagram size={size} href={links.INSTAGRAM}>
                                Instagram
                            </Instagram>
                            <Patreon size={size} href={links.PATREON}>
                                Patreon
                            </Patreon>
                        </span>
                    </Container>
                </Jumbotron>
            </span>
        );
    }
}

export default HeaderSplash;