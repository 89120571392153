import React, { Component } from 'react';
import Microlink from '@microlink/react';
import {
    Row,
    Col
} from 'react-bootstrap';

import { FeaturedURLs } from './FeaturedMediaURLs';
import { webAddresses } from '../utils/Constants';

const URL = `${webAddresses.SHAMOOK_MELLIGANS}:8443/googleSearch`;

class Media extends Component {
    constructor(props) {
        super(props);

        this.state = {
            googleSearchResults: {}
        }

        this.setGoogleSearchResults = this.setGoogleSearchResults.bind(this);

        this.setGoogleSearchResults()
    }

    render() {
        return(
            <div className="MediaPage">
                <h1>In The Media</h1>
                <div className="FeaturedArticles">
                    <h2>Featured</h2>
                    {this.renderFeaturedArticles()}
                </div>
                <div className="CardDivider" />
                <div className="RestOfMedia">
                    <h3>Other Mentions</h3>
                    {this.renderRSSFeed()}
                </div>
            </div>
        );
    }

    async setGoogleSearchResults() {
        const results = await fetch(URL);
        this.setState({googleSearchResults: await results.json()});
    }

    renderRSSFeed() {
        const linkComponent = [];
        const displayComponent = [];

        if(this.state.googleSearchResults.items) {
            this.state.googleSearchResults.items.forEach((result, key) => {
                linkComponent.push(
                    <Microlink url={result.link} key={key} />
                );
            });

            for(var i = 0; i < linkComponent.length; i+=2) {
                displayComponent.push(
                    <div className="RSSFeedBlock" key={i}>
                        <Row key={"row"+i}>
                            <Col key={"column"+i}>
                                {linkComponent[i]}
                            </Col>
                            <Col key={"column"+i+1}>
                                {linkComponent[i+1]}
                            </Col>
                        </Row>
                    </div>
                )
            }
        }

        return displayComponent;
    }

    renderFeaturedArticles() {
        const linkComponent = [];

        FeaturedURLs.forEach((link, key) => {
            linkComponent.push(
                <Microlink url={link} key={key} size="large" />
            );
        });

        return(
            <div className="FeaturedArticlesBlock">
                <Row>
                    <Col>
                        {linkComponent[0]}
                    </Col>
                    <Col>
                        {linkComponent[1]}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {linkComponent[2]}
                    </Col>
                    <Col>
                        {linkComponent[3]}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Media;