import React, { Component } from 'react';

class About extends Component {
    render() {
        return(
            <div className="AboutPage">
                <h1>Welcome!</h1>
                <p>
                    Welcome to my website where it's all about faking it. Feel free to look at my work, see some examples of me in the media and, if you're looking for a personal piece of fakery, check out my contact page where you can get in touch with your commission requests!
                </p>
                <p><b>P.S. Don't forget to check out my Patreon!</b></p>
                <h2>About Me</h2>
                <p>
                    I'm a DeepFake artist, who creates videos on a commission basis and also for YouTube.
                </p>
            </div>
        );
    }
}

export default About;