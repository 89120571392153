import React from 'react';
import {
    FaYoutube,
    FaTwitter,
    FaInstagram,
    FaPatreon
} from 'react-icons/fa';

import {
    colors
} from './Constants';

const Youtube = (props, {size=30}) => {
    return(
        <span className="YoutubeWidget">
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                <FaYoutube color={colors.YOUTUBE} size={size}/>
                { props.children }
            </a>
        </span>
    );
}

const Twitter = (props, {size=30}) => {
    return(
        <span className="TwitterWidget">
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                <FaTwitter color={colors.TWITTER} size={size}/>
                { props.children }
            </a>
        </span>
    );
}

const Instagram = (props, {size=30}) => {
    return(
        <span className="InstagramWidget">
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                <FaInstagram color={colors.INSTAGRAM} size={size}/>
                { props.children }
            </a>
        </span>
    );
}

const Patreon = (props, {size=30}) => {
    return(
        <span className="PatreonWidget">
            <a href={props.href} target="_blank" rel="noopener noreferrer">
                <FaPatreon color={colors.PATREON} size={size}/>
                { props.children }
            </a>
        </span>
    );
}

export { Youtube, Twitter, Instagram, Patreon };