import React, { Component } from 'react';
import { 
    Button,
    Row,
    Col
} from 'react-bootstrap';

import VideoExampleComponent from './VideoExampleComponent';
import { webAddresses } from '../utils/Constants';

class Examples extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videos: [],
            numberRendered: 2,
        };

        this.handleMore = this.handleMore.bind(this);

        this.setVideos();
    }

    render() {
        return(
            <span className="Examples">
                <h1>Examples of Sham00k's work</h1>
                {this.createVideoComponentList(this.state.videos).slice(0, this.state.numberRendered)}
                {this.renderMoreButton()}
            </span>
        );
    }

    renderMoreButton() {
        return(
            <Button className="MoreButton" onClick={this.handleMore}>
                More...
            </Button>
        );
    }

    createVideoComponentList(data) {
        const list = [];
        if(data.items) {
            for(var i=0; i < data.items.length; i+=2) {
                list.push(
                    <div className={"Row"+i} key={"Row"+i}>
                        <Row key={i}>
                            <Col key={i}>
                                <VideoExampleComponent item={data.items[i]} key={"video"+i} />
                            </Col>
                            <Col key={i+1}>
                                <VideoExampleComponent item={data.items[i+1]} key={"video"+i+1} />
                            </Col>
                        </Row>
                        <div className="CardDivider" />
                    </div>
                )
            }
        }

        return list;
    }

    async setVideos() {
        const videoResponse = await fetch(
            `${webAddresses.SHAMOOK_MELLIGANS}:8443/videos`
        );

        const response = await videoResponse.json();

        if(response.error) {
            console.log(response.error)
        } else {
            this.setState({videos: response});
        }
    }

    handleMore() {
        const prevState = this.state;
        this.setState({numberRendered: prevState.numberRendered+2});
    }
}

export default Examples;