import React, { Component } from 'react';

import ContactForm from './ContactForm';
import { RequestAlert } from '../utils/RequestAlert';

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAlert: false
        };

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleAlertOpen = this.handleAlertOpen.bind(this);
    }

    render() {
        return(
            <span className="ContactPage">
                <h1>Contact</h1>
                <RequestAlert onClose={this.handleAlertClose} show={this.state.showAlert} message="You have successfully contacted Shamook" />
                <ContactForm onSuccess={this.handleAlertOpen} />
            </span>
        );
    }

    handleAlertOpen() {
        this.setState({showAlert: true});
    }

    handleAlertClose() {
        this.setState({showAlert: false});
    }
}

export default Contact;