import React from 'react';
import {
    FaYoutube,
    FaTwitter,
    FaInstagram,
    FaPatreon
} from 'react-icons/fa';

import {
    colors,
    links
} from './Constants';

const size = 30;

const VerticalMenu = () => {
    return(
        <span className="VerticalMenu">
            <a href={links.YOUTUBE} target="_blank" rel="noopener noreferrer"><FaYoutube size={size} color={colors.YOUTUBE} /></a>
            <a href={links.TWITTER} target="_blank" rel="noopener noreferrer"><FaTwitter size={size} color={colors.TWITTER} /></a>
            <a href={links.INSTAGRAM} target="_blank" rel="noopener noreferrer"><FaInstagram size={size} color={colors.INSTAGRAM} /></a>
            <a href={links.PATREON} target="_blank" rel="noopener noreferrer"><FaPatreon size={size} color={colors.PATREON} /></a>
        </span>
    );
}

export { VerticalMenu };