import React from 'react';
import {
    FaTools
} from 'react-icons/fa';

const BuiltTag = () => {
    return(
        <div className="MyInfo">
            <a href="https://melligans.com" target="_blank" rel="noopener noreferrer">
                <FaTools className="BuiltBy" size={30}/>
            </a>
            <div className="IconText">
                    <p>Built by Felix Melligan</p>
            </div>
        </div>
    );
}

export { BuiltTag };