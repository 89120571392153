import React from 'react';
import {
    Alert
} from 'react-bootstrap';

const RequestAlert = (props) => {
    if(props.show) {
        return(
            <Alert dismissible onClose={props.onClose} variant="success">
                {props.message}
            </Alert>
        );
    }
    return [];
};

export { RequestAlert };