import React from 'react';

import Header from './header/Header';
import { VerticalMenu } from './utils/VerticalMenu';
import { BuiltTag } from './utils/BuiltTag';

function App() {
  return (
    <div className="App" >
      <Header />
      <VerticalMenu />
      <BuiltTag />
    </div>
  );
}

export default App;
