import React, { Component } from 'react';
import {
    Form,
    Button,
    Col,
    Alert
} from 'react-bootstrap';

import { webAddresses } from '../utils/Constants';

class ContactForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            validated: false,
            name: '',
            email: '',
            subject: '',
            request: '',
            errors: {},
            alertShow: false,
            alertMessage: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return(
            <Form className="ContactForm" onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control name='name' required type="text" placeholder="Name" value={this.state.name} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control name='email' required type="email" placeholder="Enter email" value={this.state.email} onChange={this.handleChange} />
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridRequest">
                    <Form.Label>Request</Form.Label>
                    <Form.Control name='request' required as="textarea" rows="4" value={this.state.request} onChange={this.handleChange} />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
                {
                    this.state.alertShow
                    ?
                    <Alert variant="danger" onClose={() => this.setState({alertShow: false})} dismissible>
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>{this.state.alertMessage}</p>
                    </Alert>
                    :
                    []
                }
            </Form>
        );
    }

    handleChange(event) {
        event.currentTarget.checkValidity();
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value, 
        });
    }

    async handleSubmit(event) {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            this.setState({
                alertShow: true,
                alertMessage: 'Invalid input'
            });
        } else {
            await this.handlePost(this.state.email, this.state.name, this.state.subject, this.state.request);
            this.setState({
                validated: true,
                email: '',
                name: '',
                subject: 'Website Query',
                request: ''
            });
            this.props.onSuccess();
        }
    };

    async handlePost(from, name, subject, request) {
        const url = `${webAddresses.SHAMOOK_MELLIGANS}:8443/contact`;
        const data = {
            "from": from,
            "name": name,
            "subject": subject,
            "request": request
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const json = await response.json();
            if(json.state === "ERROR") {
                this.setState({
                    errors: json.info,
                    alertShow: true,
                    alertMessage: json.info
                });
            } else {
                this.setState({
                    errors: '',
                    alertShow: false,
                    alertMessage: ''
                });
            }
        } catch (error) {
            this.setState({
                errors: error,
                alertShow: true,
                alertMessage: error
            });
        }
    }
}

export default ContactForm;