import React, { Component } from 'react';

import HeaderLinks from './HeaderLinks';
import HeaderSplash from './HeaderSplash';

class Header extends Component {

    render() {
        return(
            <span className="Header">
                <HeaderSplash />
                <HeaderLinks />
            </span>
        );
    }
}

export default Header;