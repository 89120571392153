import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {
    Card
} from 'react-bootstrap';

import { webAddresses } from '../utils/Constants';

class VideoExampleComponent extends Component {
    render() {
        const videoURL = `https://www.youtube.com/watch?v=${this.props.item.id.videoId}`;

        return(
            <span className={"Video-"+this.props.item.id.videoId}>
                <Card className="Card">
                    <span className="CardBody">
                        <YouTube
                            className="YoutubeVideo"
                            videoId={this.props.item.id.videoId}
                            opts={{
                                host: "https://youtube.com",
                                origin: webAddresses
                            }}
                        />
                        <Card.Body className="CardBodyText">
                            <Card.Title>{this.decodeHtml(this.props.item.snippet.title)}</Card.Title>
                            <p>Posted {this.findPostDifference(this.props.item.snippet.publishedAt)} days ago.</p>
                            <a href={videoURL} target="_blank" rel="noopener noreferrer">
                                View on YouTube
                            </a>
                        </Card.Body>
                    </span>
                </Card>
            </span>
        );
    }

    decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    findPostDifference(date) {
        const today = new Date();
        const postDate = new Date(date);
        const diffTime = Math.abs(today - postDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        return diffDays;
    }
}

export default VideoExampleComponent;