import React, { Component } from 'react';
import {
    Tabs,
    Tab
} from 'react-bootstrap';

import Examples from '../exampleVids/Examples';
import Contact from '../contact/Contact'
import Media from '../mediaPage/Media';
import About from '../about/About';

class HeaderLinks extends Component {

  render() {
      return(
        <Tabs defaultActiveKey="about" id="uncontrolled-tab-example" className="ContentTabs" fill>
          <Tab eventKey="about" title="About Me">
            <About />
          </Tab>
          <Tab eventKey="examples" title="Examples">
            <Examples />
          </Tab>
          <Tab eventKey="media" title="In the Media">
           <Media />
          </Tab>
          <Tab eventKey="contact" title="Contact">
            <Contact />
          </Tab>
        </Tabs>
      );
  }
}

export default HeaderLinks;